import React from 'react';
import DownloadDatabaseBanner from './DownloadDatabaseBanner.style';

const EmailBanner = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Add smooth scrolling effect
    });
  };

  return (
    <DownloadDatabaseBanner>
      <div
        className="logo-container"
        onClick={scrollToTop} // Attach the scrollToTop function to the click event
        role="button" // Add role="button" for accessibility
        tabIndex={0} // Add tabIndex to make it focusable
        style={{ cursor: 'pointer' }}
      >
        <h4>Download this database file NOW.</h4>
      </div>
    </DownloadDatabaseBanner>
  );
};

export default EmailBanner;
