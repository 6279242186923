import styled from 'styled-components';

const DownloadDatabaseBanner = styled.section`
  max-width: 70%;
  margin: 20px auto;
  background-color: #03baed;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  height: 60px;
  /* padding: 0px 20px; */

  h4 {
    font-size: 22px;
  }

  @media screen and (max-width: 576px) {
    max-width: 90%;
  }
`;

export default DownloadDatabaseBanner;
