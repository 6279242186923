import styled from 'styled-components';

export const ContentWrapper = styled.div`
  max-width: 90%;
  margin: 10px auto 10px auto;
  padding: 0;
  display: flex;
  /* flex-direction: column; */
  border-bottom: 1px solid gray;

  @media screen and (max-width: 768px) {
    align-items: center; /* Center the items on smaller screens */
    flex-direction: column;
  }
`;
export const LeftColumn = styled.div`
  width: 20%;
  margin-right: 10px;
  height: 110%;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px; /* Add border radius */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  /* background-color: #f7f7f7;  */

  h4 {
    margin-bottom: 10px; /* Adjust margin */
    font-size: 18px; /* Adjust font size */
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin-bottom: 10px;
    }

    a {
      color: black;
      text-decoration: underline;
    }
  }

  .underline {
    border-bottom: 2px solid black;
  }
  @media screen and (max-width: 768px) {
    width: 90%;
    margin-bottom: 20px;
  }
`;

export const RightColumn = styled.div`
  flex: 1;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const RoundedBox = styled.div`
  max-width: 100%;
  display: flex;
  background-color: #f7f7f7;
  border-radius: 8px;
  padding: 16px;
  border: 1px solid #ccc;
  margin-bottom: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  a {
    color: black;
  }
  .company-type {
    color: black;
    font-size: 16px;
  }
  .linkdlin-icon {
    height: 20px;
    width: 20px;
    margin-right: 10px; /* Adjusted margin for icon */
  }

  h3 {
    margin-top: -8px;
    font-size: 24px;
    font-weight: bold;
    color: #03baed;
    display: flex;
    align-items: center;
    margin-bottom: 4px;
  }

  h4 {
    font-size: 14px;
  }
  .company-type {
    font-weight: 400;
    @media screen and (max-width: 768px) {
      font-size: 12px;
    }
  }
  p {
    color: black;
    margin-bottom: 8px;
    font-size: 14px;
    text-align: left;
  }

  img.icon {
    width: 150px;
    height: 150px;
    object-fit: contain;
    margin-left: 10px;
  }
`;

export const DataRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

export const DataItem = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  justify-content: center;
  margin-right: 10px;
`;
