import React, { useState, useEffect, useRef } from 'react';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import DownloadForm from '../../../containers/Custom/Resources/DownloadForm/index';
import ResourceDetailWrapper from '../../../templates/ResourceTemplate/resourceTemplate.style';
import { ContentWrapper, BannerPart1 } from './bannerResource.style';

import PropTypes from 'prop-types';
import './popup.css';

const BannerResource = ({
  title,
  metaDescription,
  blogDetail,
  html,
  resourceData,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [hasShownPopup, setHasShownPopup] = useState(false);
  const popupOverlayRef = useRef(null);

  useEffect(() => {
    const handleMouseLeave = () => {
      if (!hasShownPopup) {
        setShowPopup(true);
        setHasShownPopup(true);
      }
    };

    document.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      document.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [hasShownPopup]);

  const closePopup = () => {
    setShowPopup(false);
  };

  // Add an event listener to the overlay to close the popup when clicking outside
  const handleOverlayClick = (e) => {
    if (popupOverlayRef.current === e.target) {
      closePopup();
    }
  };

  return (
    <ContentWrapper>
      <BannerPart1>
        <div className="heading">
          <Heading
            as="h1"
            content={title}
            style={{
              margin: 0,
              padding: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          />
        </div>
        {blogDetail === false && (
          <Text className="banner-caption" content={metaDescription} />
        )}
        <div className="container">
          <div
            className="resource-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
          <DownloadForm
            className="downloadform"
            file={resourceData.file.localFile.publicURL}
            resource={resourceData.title}
          />
        </div>
      </BannerPart1>
      {showPopup && (
        <div
          className="popup-overlay"
          ref={popupOverlayRef} // Set the ref to the overlay element
          onClick={handleOverlayClick} // Call handleOverlayClick on overlay click
        >
          <div className="popup-box">
            <button onClick={closePopup} className="close-button">
              X
            </button>
            <h2>
              You Forget to <b>DOWNLOAD</b> this File for <b>FREE</b>.
            </h2>
            <p>
              Download the Excel of <b>{title}</b> <br />
              profiles and their comprehensive information at no cost.
            </p>
            <div className="popup-buttons">
              <button onClick={closePopup} className="stay-button">
                Download File NOW
              </button>
            </div>
            <p className="para">
              Try Clodura.AI - an AI-powered leadgen platform for prospecting
              <br /> and closing more deals NOW
            </p>
          </div>
        </div>
      )}
    </ContentWrapper>
  );
};

BannerResource.propTypes = {
  button: PropTypes.object,
};

BannerResource.defaultProps = {
  button: {
    type: 'button',
    fontSize: '13px',
    fontWeight: '700',
    borderRadius: '4px',
    pl: '15px',
    pr: '15px',
    colors: 'tertiaryWithBg',
    minHeight: 'auto',
    height: '40px',
  },
};

export default BannerResource;
