import styled from 'styled-components';

const EmailBannerWrapper = styled.section`
  max-width: 70%;
  margin: auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }

  @media screen and (max-width: 576px) {
    max-width: 90%;
  }
`;

export default EmailBannerWrapper;
