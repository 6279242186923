import styled from 'styled-components';

export const ContentWrapper = styled.div`
  /* max-width: 90%; */
  margin: 80px auto 50px auto;
  padding: 0;
  display: flex;
  border-bottom: 1px solid gray;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const BannerPart1 = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .heading {
    background-color: #f2fbfe;
    color: black;
    width: 100%;
    height: 8rem;
    display: flex;
    justify-content: center;
    text-align: center;
    left: 0;
    right: 0;
  }

  .container {
    display: flex;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      max-width: 90%;
      /* padding: 0; */
    }
    .resource-post-content {
      max-width: 65%;
      padding: 0px 0px 0px 40px;
      @media screen and (max-width: 480px) {
        max-width: 100%;
        padding: 5px;
        > h1 {
          font-size: 20px;
        }
      }
      > p {
        font-size: 16px;
        text-align: left;
      }
    }
  }
  > h1 {
    font-size: 24px;
  }

  > div > p {
    font-size: 15px;
  }

  /* @media screen and (max-width: 768px) {
    order: 2;
  } */
`;
