import styled from 'styled-components';

const ResourceDetailWrapper = styled.section`
  max-width: 70%;
  margin: 0 auto;

  p {
    font-size: 16px;
    text-align: left;
  }
`;

export default ResourceDetailWrapper;
