import React from 'react';
import Heading from 'common/components/Heading';
import { useStaticQuery, graphql } from 'gatsby';
import EmailBannerWrapper from './emailbanner.style';
import { Link } from 'gatsby';

const EmailBanner = () => {
  const ClientData = useStaticQuery(graphql`
    {
      allStrapiEmailBannerImage {
        nodes {
          img {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `);

  const data = ClientData.allStrapiEmailBannerImage.nodes[0];

  return (
    <EmailBannerWrapper>
      <div class="logo-container">
        <Link href="/tools/email-verifier">
          <img
            class="logo-image"
            src={`${data.img.localFile.publicURL}`}
            alt="clodura-email-banner"
          />
        </Link>
      </div>
    </EmailBannerWrapper>
  );
};

export default EmailBanner;
