import React from 'react';
import Text from 'common/components/Text';
import {
  ContentWrapper,
  RoundedBox,
  DataRow,
  DataItem,
  LeftColumn,
  RightColumn,
} from './datacomponent.style';
import PropTypes from 'prop-types';
import WebIcon from '../../../common/assets/image/agencyDigital/DataBaseImages/web_icon.svg';
import Linkdlin from '../../../common/assets/image/agencyDigital/DataBaseImages/LinkedIn_icon.svg';
import PhoneIcon from '../../../common/assets/image/agencyDigital/DataBaseImages/phone_icon.svg';
import CompanySizeIcon from '../../../common/assets/image/agencyDigital/DataBaseImages/CompanyCount_icon.svg';
import DownloadDatabaseBanner from '../DownloadDatabaseBanner';

// ... (other imports)

const JsonData = ({ info }) => {
  if (!info || !info.companies || info.companies.length === 0) {
    return null; // Return null or any other appropriate content when no data is available
  }

  return (
    <ContentWrapper>
      <LeftColumn>
        <h4>Most Downloaded Lists</h4>
        <span className="underline"></span>
        <ul>
          <li>
            <a href="https://www.clodura.ai/database-center/list-of-top-software-product-based-companies-in-us-tier-i-5001-10000-employees/">
              List of Top Software Product Based Companies With Contacts &
              Emails in US- Tier I
            </a>
          </li>
          <li>
            <a href="https://www.clodura.ai/database-center/list-of-top-it-services-companies-in-india/">
              List of Top IT Services Companies With Contacts & Emails in India
            </a>
          </li>
          <li>
            <a href="https://www.clodura.ai/database-center/list-of-top-enterprise-software-product-based-companies-in-us/">
              List of Top Enterprise Software Product Based Companies With
              Contacts & Emails in US
            </a>
          </li>
          <li>
            <a href="https://www.clodura.ai/database-center/list-of-martech-companies-with-contacts-emails/">
              List of MarTech Companies With Contacts & Emails
            </a>
          </li>
          <li>
            <a href="https://www.clodura.ai/database-center/sales-pipeline-excel-template/">
              Sales Pipeline Excel Template for Tracking Pipeline
            </a>
          </li>
          <li>
            <a href="https://www.clodura.ai/database-center/list-of-top-education-tech-companies-with-contacts-emails/">
              List of Top Education Tech Companies With Contacts and Emails
            </a>
          </li>
          <li>
            <a href="https://www.clodura.ai/database-center/list-of-top-software-companies-in-us/">
              List of Top Software Product Based Companies With Contacts &
              Emails in US - Tier II
            </a>
          </li>
          <li>
            <a href="https://www.clodura.ai/database-center/top-10-medtech-companies-in-india/">
              Top 10 MedTech Companies in India
            </a>
          </li>
          <li>
            <a href="https://www.clodura.ai/database-center/list-of-top-iot-software-companies-with-contacts-emails/">
              List of Top IoT Software Companies With Contacts & Emails
            </a>
          </li>
          <li>
            <a href="https://www.clodura.ai/database-center/list-of-top-healthtech-companies-with-contacts-emails/">
              List of Top Healthtech Companies With Contacts & Emails
            </a>
          </li>
        </ul>
      </LeftColumn>
      <RightColumn>
        {info.companies.map((company, index) => (
          <React.Fragment key={index}>
            <RoundedBox key={index}>
              <div>
                <h3>
                  <strong>{company?.CompanyName}</strong>
                  {company?.Industry && (
                    <span className="company-type">({company.Industry})</span>
                  )}
                  {company?.LinkedinLink && (
                    <a
                      href={company.LinkedinLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="linkdlin-icon"
                        src={Linkdlin}
                        alt="LinkedIn Icon"
                        style={{ marginLeft: '5px' }}
                      />
                    </a>
                  )}
                </h3>

                <DataRow>
                  {company?.Website && (
                    <DataItem>
                      <a
                        href={
                          company.Website.startsWith('http')
                            ? company.Website
                            : `https://${company.Website}`
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          display: 'inline-flex',
                          alignItems: 'center',
                        }}
                      >
                        <img
                          src={WebIcon}
                          alt="Web Icon"
                          style={{
                            height: '20px',
                            width: '20px',
                            marginRight: '2px',
                          }}
                        />
                        {company.Website}
                      </a>
                    </DataItem>
                  )}

                  {company?.BoardlineNumbers && (
                    <DataItem style={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        src={PhoneIcon}
                        alt="Phone Icon"
                        style={{
                          height: '20px',
                          width: '20px',
                          marginRight: '2px',
                        }}
                      />
                      <div style={{ display: 'inline' }}>
                        <h4>{`${company.BoardlineNumbers.split(',')[0]}`}</h4>
                      </div>
                    </DataItem>
                  )}

                  {company?.CompanySize && (
                    <DataItem>
                      <img
                        src={CompanySizeIcon}
                        alt="CompanySize Icon"
                        style={{
                          height: '20px',
                          width: '20px',
                          marginRight: '2px',
                        }}
                      />
                      <h4>{`${company.CompanySize}`} </h4>
                    </DataItem>
                  )}
                  {company?.State && (
                    <DataItem>
                      <h4>{`State: ${company.State}`} </h4>
                    </DataItem>
                  )}

                  {company?.Country && (
                    <DataItem>
                      <h4>{`Country: ${company.Country}`} </h4>
                    </DataItem>
                  )}

                  {company?.Revenue && (
                    <DataItem>
                      <h4>{`Revenue: ${company.Revenue}`} </h4>
                    </DataItem>
                  )}
                </DataRow>
                {company?.CompanyDescription && (
                  <p>{company.CompanyDescription}</p>
                )}
              </div>
            </RoundedBox>
            {index % 10 === 9 && (
              <DownloadDatabaseBanner /> // Display the banner component after every 10th box
            )}
          </React.Fragment>
        ))}
      </RightColumn>
    </ContentWrapper>
  );
};

// ... (other propTypes and exports)

JsonData.propTypes = {
  info: PropTypes.shape({
    companies: PropTypes.arrayOf(
      PropTypes.shape({
        CompanyName: PropTypes.string,
        Country: PropTypes.string,
        Revenue: PropTypes.string,
        Website: PropTypes.string,
        Industry: PropTypes.string,
        CompanyType: PropTypes.string,
        LinkedinLink: PropTypes.string,
        BoardlineNumbers: PropTypes.string,
        CompanySize: PropTypes.string, // Changed field name
        CompanyDescription: PropTypes.string,
      })
    ),
  }),
};

export default JsonData;
